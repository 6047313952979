
.custom-cursor {
  width: 20px; 
  height: 20px; 
  background-color: white; 
  border-radius: 0%; 
  position: fixed;
  pointer-events: none; 
  z-index: 9999; 
  mix-blend-mode: difference; 
  transition: width 0.3s, height 0.3s; 
}

.custom-cursor.hovered {
  transform: translate(-50%, -50%) scale(3);
  border-radius: 50
  5;
}


.main{
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer{
  position: fixed;
  bottom: 5%;
}