@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body, html{
  margin: 0%;
  padding: 0%;
  background: red;
  color: white;
  cursor: none !important;
}

.lato-thin {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: normal;
  user-select: none;
}

.lato-light {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
  user-select: none;
}

.lato-regular {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
  user-select: none;
}

.lato-bold {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
  user-select: none;
}

.lato-black {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
  user-select: none;
}

.lato-thin-italic {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: italic;
  user-select: none;
}

.lato-light-italic {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: italic;
  user-select: none;
}

.lato-regular-italic {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: italic;
  user-select: none;
}

.lato-bold-italic {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: italic;
  user-select: none;
}

.lato-black-italic {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: italic;
  user-select: none;
}

@font-face {
  font-family: "DuneRise";
  src: local("DuneRise"), url("./fonts/Dune_Rise.ttf") format("truetype");
  font-weight: bold;
}

.dune-logo{
  font-family: 'DuneRise';
  font-size: 50px;
  user-select: none;
}

.dune-thin {
  font-family: 'DuneRise';
  font-weight: 100;
  font-style: normal;
  user-select: none;
}

.dune-light {
  font-family: 'DuneRise';
  font-weight: 300;
  font-style: normal;
  user-select: none;
}

.dune-regular {
  font-family: 'DuneRise';
  font-weight: 400;
  font-style: normal;
  user-select: none;
}

.dune-bold {
  font-family: 'DuneRise';
  font-weight: 700;
  font-style: normal;
  user-select: none;
}

.dune-black {
  font-family: 'DuneRise';
  font-weight: 900;
  font-style: normal;
  user-select: none;
}

.dune-thin-italic {
  font-family: 'DuneRise';
  font-weight: 100;
  font-style: italic;
  user-select: none;
}

.dune-light-italic {
  font-family: 'DuneRise';
  font-weight: 300;
  font-style: italic;
  user-select: none;
}

.dune-regular-italic {
  font-family: 'DuneRise';
  font-weight: 400;
  font-style: italic;
  user-select: none;
}

.dune-bold-italic {
  font-family: 'DuneRise';
  font-weight: 700;
  font-style: italic;
  user-select: none;
}

.dune-black-italic {
  font-family: 'DuneRise';
  font-weight: 900;
  font-style: italic;
  user-select: none;
}
